<template>
  <v-container v-if="!broken_page" fluid>
    <div v-if="!displayCheckList" >
      <h3 class="font-weight-medium mb-2">Checkbooks</h3>
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field
                placeholder="Search"
                v-model="searchValue"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>


        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="checkbooksForm = true"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
        </v-col>
      </v-row>
      <EasyDataTable
        :headers="headers"
        :items="checkBooksDetails"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToSeeCheck(item)">mdi-pencil</v-icon>
        </template>
        <template #pagination="{}">
          <v-btn
            class="ma-4"
            density="compact"
            :disabled="currentPage === 0"
            @click="goToPrevPage"
            >prev page</v-btn
          >
          <v-btn
            density="compact"
            class="ma-4"
            :disabled="currentPage === numberOfPages"
            @click="goToNextPage"
            >next page</v-btn
          >
        </template>
      </EasyDataTable>
    </div>
    <div v-if="displayCheckList" class="mt-4">
      <h3 class="font-weight-medium mb-2">{{ trusteeCofferNameCheckbooks }}'s Checks</h3>
      <EasyDataTable
        :headers="CheckBookHeaders"
        :items="checksDetails"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="updateCheck(item)">mdi-pencil</v-icon>
        </template>
      </EasyDataTable>
    </div>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <v-dialog v-model="checkbooksForm" width="auto">
    <New_Checkbooks_Modal_V01></New_Checkbooks_Modal_V01>
  </v-dialog>
  <v-dialog v-model="displayOneCheck" width="auto">
    <v-container
      fluid
      style="
        background-color: white;
        width: 500px;
        height: 450px;
        border-radius: 5px;
      "
    >
      <v-form  ref="update_check_form">
        <v-row class="mt-2">
          <v-col cols="12" md="12" sm="12" xs="12">
            <div style="text-align: center; margin-bottom: 20px;">
              <h3 class="d-md-inline-block font-weight-medium mr-2" >
                Check Serial Number:
              </h3>
              <span>{{ this.serialNumberDisplay }}</span>
            </div>
            <v-tab
              @click="secondaryThirdPartyListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select</v-tab>
            <v-text-field
              label="Bearer"
              v-model="secondaryThirdPartyFullName"
              @keyup.backspace="clearSecondaryThirdParty"
              @keydown="handleKeyDown"
            ></v-text-field>
            <v-text-field
              v-maska:[moneyOption]
              label="Amount"
              v-model="checkAmount"
            ></v-text-field>

            <v-autocomplete
              label="Check Status"
              v-model="CheckStatusItem"
              :items="CheckStatusItems"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
            <div
              class="d-flex top-20"
              style="display: flex; justify-content: end"
            >
              <v-btn color="button_color" @click="CheckIsUpdate">{{
                $t("confirm")
              }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-dialog>
  <v-dialog v-model="secondaryThirdPartyListModal" width="auto">
    <third_party_list_modal
      thirdPartyType="SECONDARY_THIRDPARTY"
    ></third_party_list_modal>
  </v-dialog>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import emitter from "@/plugins/mitt";
import checkbooksService from "@/services/checkbooks.service";
import {
  extractNumber,
  formatNumber,
  unifiedResponseHandler,
} from "@/utils/helpers";
import New_Checkbooks_Modal_V01 from "@/components/Modals/New_Checkbooks_Modal_V01.vue";
import { PAGE_SIZE } from "@/config/constants";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import third_party_list_modal from "../Modals/Third_Party_List_Modal_V01.vue";
import { MoneyOption } from "@/utils/maska_options";
import { requiredRule } from "@/utils/rules.js";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
export default {
  name: "checkbooks_v01",
  components: {
    Error_Modal,
    Success_Modal,
    New_Checkbooks_Modal_V01,
    Broken_Page_V01,
    third_party_list_modal,
  },
  data() {
    return {
      headers: [
        { text: "Trustee Coffer Name", value: "trusteeCofferName" },
        { text: "Created By", value: "createdBy" },
        { text: "Check Serial Number Start", value: "checkSerialNumberStart" },
        { text: "Check Serial Number End", value: "checkSerialNumberEnd" },
        { text: "See checks", value: "edit" },
      ],
      CheckBookHeaders: [
        { text: "Serial No.", value: "serialNumber" },
        { text: "Bearer", value: "payerOrPayee" },
        { text: "Amount", value: "amount" },
        { text: "Check Status", value: "checkStatus" },
        { text: "Update check", value: "edit" },
        { text: "Modified By", value: "modifiedBy" },
        { text: "Modified Date", value: "modifiedDate" },
      ],
      CheckStatusItems: [
        { name: "New", id: 0 },
        { name: "Signed", id: 1 },
        { name: "Used", id: 2 },
        { name: "Void", id: 3 },
        { name: "On Hold", id: 4 },
        { name: "Bounced", id: 5 },
        { name: "Deposited", id: 6 },
      ],
      CheckStatusItem: null,
      checkBooks: [],
      checks: [],
      itemsSelected: [],
      searchField: "",
      searchValue: "",
      error: false,
      success: false,
      message: null,
      info: false,
      tAccountListModal: false,
      tAccount: null,
      CheckSerialNumberEnd: null,
      CheckSerialNumberStart: null,
      checkbooksForm: false,
      broken_page: false,
      currentPage: 0,
      pageSize: PAGE_SIZE,
      totalItems: 0,
      displayCheckList: false,
      displayOneCheck: false,
      checkAmount: null,
      secondaryThirdPartyListModal: false,
      CheckIsUpdateData: [],
      secondaryThirdParty: null,
      requiredRule: requiredRule,
      checkByCheckBookIdForNextTime: null,
      trusteeCofferNameCheckbooks: null,
      currency: null,
      loading: false,
      serialNumberDisplay: null,
      checkIsGoingToUpdate: false,
    };
  },
  methods: {
    findCheckStatusItem(checkStatusName) {
      const checkStatusItem = this.CheckStatusItems.find(
        (item) => item.name === checkStatusName
      );
      console.log("checkStatusItem", checkStatusItem);
      return checkStatusItem ? checkStatusItem : null;
    },
    clearSecondaryThirdParty() {
      this.secondaryThirdParty = null;
    },
    handlePageChange(pageInfo) {
      this.currentPage = pageInfo.currentPage;
      this.getCurrency();
    },
    goToPrevPage() {
      this.currentPage = this.currentPage - 1;
      this.getTransactions();
    },
    goToNextPage() {
      this.currentPage = this.currentPage + 1;
      this.getTransactions();
    },
    async getCheckbooks() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await checkbooksService.getCheckbooks(this.currentPage, this.pageSize)
      );
      if (response.success) {
        this.checkBooks = response.data.data;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async goToSeeCheck(item) {
      console.log(item);
      this.trusteeCofferNameCheckbooks = item.trusteeCofferName;
      this.currency = item.currency;
      this.loading = true;
      let response = unifiedResponseHandler(
        await checkbooksService.getCheckByCheckBookId(item.checkBookId)
      );
      this.checkByCheckBookIdForNextTime = item;
      if (response.success) {
        this.loading = false;
        this.checks = response.data;
      } else {
        this.broken_page = true;
      }
      this.displayCheckList = true;
    },
    updateCheck(item) {
      this.checkIsGoingToUpdate = true;
      this.displayOneCheck = true;
      this.CheckIsUpdateData = item;
      (this.id = this.CheckIsUpdateData.id),
        (this.checkAmount = this.CheckIsUpdateData.amount),
        (this.CheckStatusItem = this.findCheckStatusItem(
          this.CheckIsUpdateData.checkStatus
        ));
      (this.secondaryThirdPartyFullName = this.CheckIsUpdateData.payerOrPayee),
        (this.serialNumberDisplay = this.CheckIsUpdateData.serialNumber);
    },
    async CheckIsUpdate() {
      let { valid } = await this.$refs.update_check_form.validate();
      if (valid) {
        let form = {
          amount: extractNumber(this.checkAmount),
          checkStatus: this.CheckStatusItem.id,
          payerPayeeId: this.secondaryThirdPartyId,
        };

        let response = unifiedResponseHandler(
          await checkbooksService.updatePaymentCheck(
            this.checkByCheckBookIdForNextTime.checkBookId,
            this.CheckIsUpdateData.id,
            form
          )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.displayOneCheck = false;
          this.checkAmount = null;
          this.CheckStatusItem = null;
          this.secondaryThirdPartyId = null;
          this.secondaryThirdParty = null;
          this.secondaryThirdPartyFullName = null;
          this.serialNumberDisplay = null;
          this.checkIsGoingToUpdate = false;
          this.goToSeeCheck(this.checkByCheckBookIdForNextTime);
        } else {
          this.error = true;
        }
      }
    },
  },
  computed: {
    moneyOption() {
      if (this.selectedCurrency) {
        const option = MoneyOption(this.selectedCurrency.symbol);
        return option;
      } else {
        return MoneyOption(null);
      }
    },
    numberOfPages() {
      return Math.floor(this.totalItems / this.pageSize);
    },

    checksDetails() {
      return this.checks.map((check) => {
        let checkStatusFormatted;
        switch (check.checkStatus) {
          case "NEW":
            checkStatusFormatted = "New";
            break;
          case "SIGNED":
            checkStatusFormatted = "Signed";
            break;
          case "USED":
            checkStatusFormatted = "Used";
            break;
          case "VOID":
            checkStatusFormatted = "Void";
            break;
          case "ON_HOLD":
            checkStatusFormatted = "On Hold";
            break;
          case "BOUNCED":
            checkStatusFormatted = "Bounced";
            break;
          case "DEPOSITED":
            checkStatusFormatted = "Deposited";
            break;
          default:
            checkStatusFormatted = check.checkStatus;
        }
        return {
          id: check.id,
          amount: formatNumber(check.amount) + " " + this.currency,
          checkStatus: checkStatusFormatted,
          payerOrPayee: check.payerOrPayee ? check.payerOrPayee.fullName : null,
          payerOrPayeeId: check.payerOrPayee ? check.payerOrPayee.id : null,
          serialNumber: check.serialNumber,
          modifiedDate: new Date(check.modifiedDate)
            .toISOString()
            .split("T")[0],
          modifiedBy: check.modifiedBy,
        };
      });
    },
    checkBooksDetails() {
      return this.checkBooks.map((checkBook) => {
        return {
          checkSerialNumberEnd: checkBook.checkSerialNumberEnd,
          checkSerialNumberStart: checkBook.checkSerialNumberStart,
          trusteeCofferName: checkBook.trusteeCofferName,
          createdBy: checkBook.createdBy,
          checkBookId: checkBook.checkBookId,
          currency: checkBook.currency.symbol,
        };
      });
    },

    secondaryThirdPartyFullName() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.fullName;
      } else if (this.checkIsGoingToUpdate) {
        return this.CheckIsUpdateData.payerOrPayee;
      } else {
        return null;
      }
    },
    secondaryThirdPartyId() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.id;
      } else if (this.checkIsGoingToUpdate) {
        return this.CheckIsUpdateData.payerOrPayeeId;
      } else {
        return null;
      }
    },
  },
  async mounted() {
    await this.getCheckbooks();
    emitter.on("chosenAccount", (value) => {
      this.tAccount = value;
      this.tAccountListModal = false;
    });
    emitter.on("new_checkbook", (value) => {
      this.getCheckbooks();
      this.checkbooksForm = false;
      this.message = value;
      console.log("new_currency", value);
      console.log("hey", value);
      // this.success = true;
    });
    emitter.on("new_third_party", (value) => {
      if (
        (value.thirdPartyType === "SECONDARY_THIRDPARTY") &
        this.secondaryThirdPartyListModal
      ) {
        this.secondaryThirdParty = value;
        this.secondaryThirdPartyListModal = false;
      }
    });
  },
};
</script>
