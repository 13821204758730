<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">Bills History</h3>
      <v-row class="mb-2">
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field
                placeholder="Search"
                v-model="searchValue"
                @keyup.enter="searchForItem(searchValue)"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
              color="button_color"
              @click="QBillsReportModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="billPaymentDetails"
            >
              <v-icon>mdi-export</v-icon>
            </download-excel
            >
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          :headers="headers"
          :items="billPaymentDetails"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
          table-class-name="customize-table"
          :loading="loading"
          :body-row-class-name="bodyRowClassNameFunction"
          no-hover
          v-model:serverOptions="serverOptions"
          :server-items-length="totalItems"
      >
        <template #pagination="{}">
          <v-btn
              density="compact"
              class="ma-4"
              :disabled="serverOptions.page === 1"
              @click="goToPrevPage">
            prev page
          </v-btn>
          <v-btn
              class="ma-4"
              density="compact"
              :disabled="serverOptions.page === numberOfPages"
              @click="goToNextPage"
          >next page
          </v-btn>
        </template>
        <template #item-amount="{ amount }">
          <div class="currency-amount">
            <span class="currency">{{
                splitCurrencyValue(amount).currency
              }}</span>
            <span class="text-right">{{
                splitCurrencyValue(amount).value
              }}</span>
          </div>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="QBillsReportModal" width="auto">
      <Q_BillsReport_Modal_V01></Q_BillsReport_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Q_BillsReport_Modal_V01 from "@/components/ModalQ/Q_BillsReport_Modal_V01.vue";
import {formatNumber, pageResponseHandler} from "@/utils/helpers.js";
import manual_transferService from "@/services/manual_transfer.service.js";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "bills_history_v01",
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_BillsReport_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Event", value: "id"},
        {text: "Account", value: "accountCofferName"},
        {text: "Purpose of Payment", value: "transactionTypeId"},
        {text: "Subledger", value: "fullName"},
        {text: "Amount", value: "amount"},
        {text: "Note", value: "description"},
        {text: "User", value: "user"},
        {text: "Occurrence Date", value: "occurrenceDate"},
      ],
      searchValue: "",
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      totalItems: 0,
      message: null,
      broken_page: false,
      success: false,
      error: false,
      QBillsReportModal: false,
      billPayment: [],
      loading: false,
      debounceTimer: null,
    };
  },

  methods: {
    async searchForItem(searchValue) {
      if (this.isOnlySpaces(searchValue)) {
        this.searchValue = '';
        return;
      }
      searchValue = searchValue.replace(/^\s+|\s+$/g, '');
      const {page, rowsPerPage} = this.serverOptions;
      this.loading = true;
      let response = pageResponseHandler(
          await manual_transferService.searchByAccountCofferName(
              searchValue,
              page - 1,
              rowsPerPage
          )
      )
      if (response.success) {
        this.billPayment = response.data;
        this.totalItems = response.totalItems;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    isOnlySpaces(str) {
      return str.trim().length === 0;
    },
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case "Completed":
          return "row-completed";
        case "Canceled":
          return "row-canceled";
        case "Initiated":
          return "row-process";
        case "Admin Approved":
        case "Waiting for Approved":
        case "Waiting for Approval":
        case "Commission Add":
        case "Receive Confirmed":
        case "Trustee Selected":
        case "Trustee Confirmed":
        case "Transaction Requested":
          return "row-other-status";
        case "Approved":
          return "row-approved";
        default:
          return "";
      }
    },
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
      this.getBillPayment();
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
      this.getBillPayment();
    },
    async getBillPayment() {
      this.loading = true;
      let response = pageResponseHandler(
          await manual_transferService.getAll(this.serverOptions.page - 1, this.serverOptions.rowsPerPage)
      );
      if (response.success) {
        this.loading = false;
        this.billPayment = response.data;
        this.totalItems = response.totalItems;
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getBillPayment();
  },
  computed: {
    billPaymentDetails() {
      return this.billPayment.map((bill) => ({
        id: bill.id,
        accountCofferName: bill.accountCofferName,
        transactionTypeId: bill.tranTypeDescription,
        fullName: bill.fullName,
        amount: bill.currency.symbol + " " + formatNumber(bill.amount),
        description: bill.description,
        user: bill.createdBy,
        occurrenceDate: new Date(bill.occurrenceDate)
            .toISOString()
            .split("T")[0],
      }));
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        clearTimeout(this.debounceTimer);
        this.accountCofferName = "";
        this.serverOptions.page = 1;
        this.getBillPayment();
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        this.getBillPayment();
      },
      deep: true,
    },
  },
};
</script>
