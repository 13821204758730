<template>
    <v-container  v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">Company Database</h3>
        <v-row>
          <v-col cols="12" md="4" sm="8">
            <div class="d-flex">
              <v-text-field
                  placeholder="Search"
                  v-model="searchValue"
              >
                <template v-slot:prepend-inner>
                  <v-icon
                      icon="mdi-magnify"
                      variant="tonal"
                      color="button_color"
                      class="mr-3"
                  >
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </v-col>

        <v-col class="d-flex justify-end">
          <v-btn color="button_color" @click="NewCompanyReport()" icon="mdi-plus" class="ml-2">
            </v-btn>
          <v-btn color="button_color" @click="QCompany = true" icon="mdi-help" class=" ml-2">
            </v-btn>
            <v-btn color="button_color" icon="mdi-export" class=" ml-2">
              <download-excel :data="companyList" ><v-icon>mdi-export</v-icon></download-excel>
            </v-btn>
        </v-col>
       
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="companyList"
      item-title="name"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      class="custom-data-table"
    >
      <!-- <template #item-edit="item">
        <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
      </template>
      <template #item-delete="item">
        <v-icon @click="deleteThirdParty(item)">mdi-delete</v-icon>
      </template> -->
    </EasyDataTable>

    </v-container>

    <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span  @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span  @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
<v-dialog v-model="QCompany" width="auto">
      <Q_CompanyDatabase_Modal_V01></Q_CompanyDatabase_Modal_V01>
    </v-dialog>
</template>
<script>
import Q_CompanyDatabase_Modal_V01 from "@/components/ModalQ/Q_CompanyDatabase_Modal_V01.vue";

import {
  setPage,
} from "@/utils/helpers";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
export default {
  name: "company_report_v01",
  components: {
    Broken_Page_V01,
    Q_CompanyDatabase_Modal_V01
  },
  data() {
    return {
      headers: [
        { text: "Account Number:", value: "accountNumber" },
        { text: "Company Name:", value: "fullName" },
        { text: "Company Email:", value: "email" },
        { text: "Company Phone Number:", value: "phoneNumber" },
        { text: "Edit/Details", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      companyList: [],
      itemsSelected: [],
      searchField: "",
      searchValue: "",
      broken_page: false,
      success: false,
      error: false,
      companyName: null,
      companyEmail: null,
      AccountNumber: null,
      companyPhoneNumber: null,
      confirmation: false,
      confirmed: false,
      QCompany: false,
      message: null,
    };
  },
  methods: {
    NewCompanyReport() {
      setPage("new_company_database");
    },

  },
//   async mounted() {
//     await this.getTrusteesList();
//   },
};
</script>